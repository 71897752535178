.b-video_content {
	@include g-section_holder;

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 20px;
		text-align: center;

		@include media(lg-up) {
			font-size: 32px;
		}
	}

	&-inner {
		position: relative;

		.l-content & {
			margin: 0 auto;
			max-width: $content-limit-lg;
		}
	}

	&-player {
		display: block;
		height: auto;
		min-height: 225px;
		width: 100%;

		@include media(md) {
			min-height: 350px;
		}

		@include media(lg-up) {
			min-height: 475px;
		}
	}

	&-cover {
		background-repeat: no-repeat;
		background-size: 100% 100%;
		bottom: 0;
		cursor: pointer;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
}
