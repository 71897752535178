.b-article {
	color: $color-primary;
	display: flex;
	font-size: 15px;

	@include media(sm) {
		flex-direction: column;
	}

	@include media(md-up) {
		gap: 33px;
		justify-content: space-between;
	}

	&-image {
		flex: 0 0 300px;

		@include media(sm) {
			margin-bottom: 16px;
			order: -1;
		}
	}

	&-description {
		@include media(sm) {
			width: 100%;
		}

		@include media(md-up) {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
		}
	}

	&-image ~ &-description {
		@include media(md-up) {
			align-items: flex-end;
		}
	}

	&-text {
		margin-bottom: auto;
		margin-top: auto;
		width: 100%;
	}

	&-title {
		font-weight: 600;
		margin-bottom: 16px;
	}

	&-line {
		background-color: $color-divider;
		border-style: none;
		height: 1px;
		margin: 35px 0;
	}

	p {
		margin-bottom: 16px;
	}
}
