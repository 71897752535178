.b-workshops {
	margin: 0 auto;
	max-width: $content-limit-lg;

	@include media(md) {
		padding: 0 24px;
	}

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 20px;
		text-align: center;

		@include media(lg-up) {
			font-size: 32px;
		}

		@include media(sm) {
			.b-user_content & {
				font-size: 24px;
			}
		}
	}

	&-grid {
		@include g-grid;
	}

	&-tile {
		grid-column: span 6;
		position: relative;

		@include media(md-up) {
			&.m-col_3 {
				grid-column: span 4;
			}
		}

		.g-content_tile-caption {
			position: relative;
		}
	}

	&-link {
		display: block;
		height: 100%;

		&:hover {
			text-decoration: none;
		}
	}

	&-name {
		font-size: 13px;
		margin-top: 5px;

		&_icon {
			color: $color-action-obj;
		}

		&_value {
			font-weight: 600;
			text-decoration: underline;
		}
	}

	&-difficulty {
		background-color: $color-bg-workshops;
		border-radius: $controls-radius;
		bottom: calc(100% + 10px);
		font-size: 0;
		left: 10px;
		padding: 9px 12px;
		position: absolute;
	}

	&-status {
		background-color: $color-action-obj;
		border-radius: 4px 4px 0 0;
		color: $color-white;
		font-size: 15px;
		left: 0;
		padding: 7px 10px;
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%;
	}

	&-actions {
		grid-column: span 6;
		margin-top: 35px;
		text-align: center;

		@include media(md-up) {
			grid-column: span 12;
		}
	}
}
