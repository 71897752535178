.b-artisan {
	&-container {
		margin-bottom: 35px;

		@include media(md-up) {
			margin-bottom: 60px;

			& + & {
				margin-top: 150px;
			}
		}
	}

	&-item {
		@include media(md-up) {
			& + & {
				margin-top: 150px;
			}
		}
	}

	&-top {
		margin: 0 auto;
		max-width: 645px;
		padding: 0 25px;
		text-align: center;
	}

	&-main_icon {
		color: $color-action-obj;
		margin: 0 auto 15px;
		max-width: 30px;
	}

	&-main_title {
		@include g-heading_4;

		color: $color-action-obj;

		@include media(lg-up) {
			font-size: 32px;
		}
	}

	&-main_description {
		color: $color-primary;
		font-size: 15px;
		margin-top: 20px;
	}

	&-bg1 {
		left: 0;
		position: absolute;
		top: 0;
		transform: translate(calc(-50% + 35px), -45%);
		z-index: -1;

		@include media(md-up) {
			top: 50%;
			transform: translate(calc(-50% + 35px), -50%);
		}
	}

	&-wrapper {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 40px auto;
		max-width: $content-limit-lg;
		padding: 0 35px;
		position: relative;

		@include media(md-up) {
			align-items: center;
			flex-direction: row;
			margin: 30px auto;
		}
	}

	&-main_text {
		max-width: 470px;
		padding-right: 35px;
		position: relative;

		@include media(sm) {
			order: -1;
		}
	}

	&-main_image {
		position: relative;

		@include media(sm) {
			align-self: flex-end;
			margin: 20px 0;
		}
	}

	&-main_image ~ &-main_text {
		@include media(md-up) {
			padding-left: 35px;
			padding-right: 0;
		}

		@include media(lg-up) {
			margin-right: -80px;
		}
	}

	&-image {
		display: block;
		max-width: 300px;
		min-width: 230px;

		@include media(sm) {
			max-width: 400px;
		}

		.g-image {
			border-radius: $global-radius;
		}
	}

	&-main_image_badge {
		background-color: $color-bg-slot-pink;
		border: 8px solid $color-white;
		border-radius: 50%;
		height: 60px;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
		width: 60px;

		@include media(md-up) {
			border: 10px solid $color-white;
			height: 76px;
			transform: translate(35%, -35%);
			width: 76px;
		}
	}

	&-badge_icon {
		color: $color-action-obj;
		height: auto;
		left: 50%;
		position: relative;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 22px;

		@include media(md-up) {
			width: 26px;
		}
	}

	&-main_image_bg {
		left: 100%;
		position: absolute;
		top: 100%;
		transform: translate(-70%, -70%);
		z-index: -1;
	}

	&-main_image_svg {
		height: 85px;
		width: 85px;

		@include media(md-up) {
			height: 156px;
			width: 156px;
		}
	}

	&-main_image_svg_line {
		height: 210px;
		width: 320px;

		@include media(md-up) {
			width: 710px;
		}
	}

	&-quote {
		@include g-heading_4;

		color: $color-accent;

		@include media(md-up) {
			font-size: 40px;
		}
	}

	&-quote_description {
		color: $color-action-obj;
		font-size: 17px;
		font-weight: 700;
		margin-top: 20px;

		@include media(sm) {
			font-weight: 600;
			max-width: 160px;
			padding-right: 10px;
		}

		a {
			text-decoration: underline;
		}
	}

	&-link {
		@include g-link;

		font-weight: 600;

		@include media(sm) {
			display: block;
			font-size: 15px;
			margin-top: 5px;
		}
	}

	&-button {
		margin-top: 20px;
	}
}
