.b-content_grid {
	background-position: center;
	background-repeat: no-repeat;
	margin-top: 40px;

	@include media(sm) {
		background-position-y: top;
		background-size: 100%;
		margin-top: 0;
	}

	&-wrapper {
		@include g-section_holder;

		padding: 45px 0;
	}

	&-title {
		@include g-heading_3;

		color: $color-title;
		text-align: center;
	}

	&-emphasis {
		padding-bottom: 15px;
		position: relative;
	}

	&-icon {
		bottom: 0;
		color: $color-bg-slot-pink;
		position: absolute;
		right: -10%;
		z-index: -1;
	}

	&-description {
		color: $color-primary;
		font-size: 15px;
		line-height: 22px;
		margin: 20px auto;
		max-width: 645px;
		text-align: center;
	}

	&-tiles {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		margin: auto;
		max-width: 996px;
		position: relative;
	}

	&-subtitle {
		font-weight: 600;
		margin-top: 28px;
	}

	&-text {
		font-weight: 500;
		margin-top: 10px;
	}

	&-img {
		border-radius: $global-radius;
		height: 300px;
		margin: 0 auto;

		@include media(md) {
			object-fit: contain;
		}
	}

	&-indent {
		margin-top: 70px;

		@include media(md) {
			margin-top: 30px;
		}

		@include media(sm) {
			margin-top: 10px;
		}
	}

	&-tile {
		color: $color-primary;
		display: flex;
		flex: 1 1 28%;
		flex-direction: column;
		font-size: 13px;
		line-height: 18px;
		margin: 0 20px;
		position: relative;

		@include media(md) {
			margin: 10px;
		}

		@include media(sm) {
			flex-basis: 100%;
			margin: 20px 0;
		}
	}

	&-bottom {
		margin-top: auto;
		padding-top: 20px;
		text-align: center;
	}

	&-button {
		width: 156px;
	}

	&-link {
		display: block;
		text-align: center;
		text-decoration: underline;
		width: 100%;
	}
}
