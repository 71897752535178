.l-section {
	@include g-section_holder;

	background-blend-mode: multiply;
	background-repeat: no-repeat;
	background-size: 100%;
	max-width: none;
	padding: 25px 0;

	@include media(lg-up) {
		padding-bottom: 40px;
		padding-top: 40px;
	}

	@include media(xl) {
		padding-bottom: 45px;
		padding-top: 45px;
	}

	&:first-child {
		.l-content > & {
			padding-top: 0;
		}
	}

	&.m-full_width {
		padding-left: 0;
		padding-right: 0;
	}

	&.m-hero {
		margin: 0 auto;
		max-width: $content-limit-xl;
		padding: 0;
	}

	&.m-blend_disabled {
		background-blend-mode: inherit;
	}

	&.m-bg_centered {
		@include media(lg-down) {
			background-position: 50% 50%;
			background-size: 150% 150%;
		}
	}

	&.m-black-friday {
		padding: 20px 0 40px;
	}

	&.m-video {
		margin: 0 auto;
		max-width: $content-limit-xl;
		padding-left: 0;
		padding-right: 0;

		@include media(sm) {
			padding-bottom: 20px;
			padding-top: 20px;
		}
	}

	&.m-centered {
		margin: 0 auto;
		max-width: $content-limit-xl;
	}

	&-ugc {
		margin: 0 auto;
		max-width: $content-limit-lg + 45px;
		position: relative;
		z-index: 1;

		&.l-grid_layout.m-1_up {
			max-width: $content-limit-lg + 45px;
			padding-left: 0;
			padding-right: 0;
		}
	}
}
