.b-banner_group {
	margin: 0 auto;
	max-width: $content-limit-lg;

	@include media(sm) {
		margin: 0;
	}

	&-item {
		margin-bottom: 20px;

		@include media(lg-up) {
			margin-bottom: 30px;
		}
	}

	& > &-item:last-child,
	.b-promo_impression:last-child &-item {
		margin-bottom: 0;
	}

	&-link {
		display: block;

		&:hover {
			text-decoration: none;
		}
	}
}
