.b-cat_tiles {
	@include g-section_holder;

	position: relative;

	@include media(lg-up) {
		display: none;
	}

	&-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 36px auto 0;
		max-width: 575px;

		@include media(sm) {
			margin-top: 28px;
		}

		.m-grid_mobile_carousel & {
			@include g-snap_scroll;

			flex-wrap: nowrap;
			justify-content: start;
			max-width: none;
		}
	}

	&-grid_wrapper {
		.m-grid_mobile_carousel & {
			overflow: hidden;
			padding: 0 10px;
			position: relative;
		}
	}

	&-item {
		flex: 1 0 115px;
		margin: 0 0 1px;

		.m-grid_mobile_carousel & {
			flex: 1 0 auto;
			min-width: calc(100% / 7.4);
			scroll-snap-align: start;
			width: calc(100% / 7.4);

			@include media(xl) {
				min-width: calc(100% / 8.35);
				width: calc(100% / 8.35);
			}

			@include media(md) {
				min-width: calc(100% / 4.5);
			}

			@include media(sm) {
				min-width: calc(100% / 3.5);
			}
		}
	}

	&-link {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 90px;
		justify-content: center;
		margin: 0 0 0 1px;
		outline: 1px solid $color-divider;
		padding: 8px;
		text-align: center;

		.m-grid_mobile_carousel & {
			align-items: center;
			display: flex;
			flex-direction: column;
			font-size: 15px;
			justify-content: space-evenly;
			line-height: 15px;
			max-width: 100px;
			min-height: 90px;
			min-width: 100px;
			outline: none;
			padding: 0 5px;
			text-align: center;
		}
	}

	&-img {
		color: $color-accent;
		margin-bottom: 8px;
		max-height: 30px;
		width: auto;
	}

	&-title {
		color: $color-action-obj;
		font-size: 13px;
		font-weight: 500;
		line-height: 13px;
		max-height: 3em;
	}

	.b-carousel-ctrl {
		background: none;
		border: none;
		box-shadow: none;
		color: $color-burgundy;
		display: none;
		font-size: 10px;
		margin-top: 0;

		&::before {
			background: linear-gradient(to left, $color-white 0%, rgba($color-white, 0) 100%);
			content: '';
			height: 120px;
			position: absolute;
			right: calc(100% - 15px);
			top: 50%;
			transform: translateY(-50%);
			width: 55px;

			@include media(sm) {
				width: 70px;
			}
		}

		.m-grid_mobile_carousel & {
			display: block;
		}

		&.m-prev {
			left: -38px;
			text-align: right;

			&::before {
				background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba($color-white, 0) 100%);
				left: calc(100% - 15px);
			}
		}

		&.m-next {
			right: -38px;
			text-align: left;
		}

		svg {
			position: relative;
			width: 15px;
		}
	}

	&.m-grid_mobile_carousel .b-carousel-ctrl {
		display: block;
	}
}
