.b-product_item {
	align-items: center;
	background-color: $color-bg;
	border: 1px solid $color-divider;
	border-radius: $global-radius;
	box-shadow: $depth-1;
	display: block;
	display: flex;
	font-size: 13px;
	margin-bottom: 16px;
	padding: 16px;
	transition: $motion-ease;
	transition-property: opacity;

	@include media(md-down) {
		flex-wrap: wrap;
	}

	&:hover {
		text-decoration: none;
	}

	&-image {
		flex: 0 0 76px;
		margin: 0 16px 16px 0;
		max-width: 76px;
		width: 100%;
	}

	&-picture {
		@include g-image_container(_container, aspect-ratio(1, 1));

		img {
			@include g-image_container(_img);
		}
	}

	&-title {
		font-size: 13px;
		font-weight: 600;

		@include media(md-down) {
			flex: 1 1 calc(100% - 92px);
			margin-bottom: 16px;
		}

		@include media(lg-up) {
			margin-right: 60px;
			min-width: 180px;
		}
	}

	&-description {
		@include media(md-down) {
			flex-basis: 100%;
		}
	}
}
