.b-product_info {
	margin-top: 40px;

	@include media(lg-up) {
		margin-top: 50px;
	}

	&-description {
		font-size: 13px;
		line-height: 1.5;
		margin: 0 auto;
		max-width: 984px;
		text-align: center;

		@include media(md-up) {
			font-size: 15px;
		}
	}

	&-files {
		align-content: center;
		color: $color-action-obj;
		display: flex;
		flex-wrap: wrap;
		font-size: 15px;
		font-weight: 600;
		justify-content: center;
		margin: 0 -40px;

		@include media(md-up) {
			margin-top: 10px;
		}
	}

	&-file {
		margin: 20px 40px 0;
	}

	&-link {
		margin-left: 8px;
		text-decoration: underline;

		@include hover-supported {
			&:hover {
				text-decoration: none;
			}
		}
	}

	&-video {
		background-color: $color-bg-overlay;
		margin-top: 30px;

		.b-product_video-cover {
			background-repeat: no-repeat;
			background-size: 100% 100%;
			cursor: pointer;
		}
	}

	&-steps {
		@include media(md-up) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 5px;
		}
	}

	&-step {
		margin-top: 20px;

		@include media(md-up) {
			margin-top: 25px;
			width: calc(50% - 10px);
		}
	}
}
