.b-artisan_header {
	&-line {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;

		@include media(md-down) {
			flex-direction: column;

			.b-artisan-top .b-artisan-main_icon {
				display: none;
			}
		}

		@include media(lg-up) {
			gap: 175px;
		}
	}

	&-image {
		min-width: 250px;
		overflow: hidden;

		@include media(md-down) {
			margin-bottom: 30px;
			order: -1;
		}

		@include media(lg-up) {
			.b-artisan-main_icon {
				display: none;
			}
		}
	}

	&-img {
		border-radius: 100%;
	}

	&-description {
		color: $color-primary;
		font-size: 15px;
		margin: 0 auto;
		max-width: 550px;
		text-align: center;
	}

	&-title {
		font-size: 17px;
		font-weight: 600;
		margin-bottom: 5px;
	}
}
