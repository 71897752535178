.l-hp {
	&-item {
		&:first-child > .l-grid_layout {
			padding-top: 0;
		}
	}

	&-first_item + div,
	&-first_item + section,
	&-first_item + .l-section.m-video {
		margin-top: 0;
		padding-top: 0;
	}
}
