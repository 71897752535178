.b-social_links {
	color: $color-title;

	&-title {
		display: inline-block;
		font-size: 15px;
		font-weight: 600;
		line-height: 22px;
		margin: 0 5px;
	}

	&-item {
		display: inline-block;
		margin: 0 5px;
		padding: 0 5px;

		&:hover {
			text-decoration: none;
		}
	}
}
