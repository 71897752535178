.b-product_step {
	background-color: $color-bg;
	border: 1px solid $color-divider;
	border-radius: $global-radius;
	display: flex;
	flex-direction: column;
	height: 100%;

	&-top {
		background-color: $color-bg-shade;
		border-bottom: 1px solid $color-divider;
		border-radius: $global-radius $global-radius 0 0;
		font-size: 15px;
		font-weight: 600;
		padding: 8px 16px;
	}

	&-inner {
		padding: 20px 16px;
	}

	&-bottom {
		margin-top: auto;
		padding: 0 16px 20px;
	}

	&-item {
		margin-bottom: 20px;
	}

	&-description {
		font-size: 13px;
		line-height: 1.45;
		margin-bottom: 20px;
	}

	&-description_content {
		@include line-clamping($lines: 5);

		max-height: 91px;

		&.m-show-more {
			display: block;
			max-height: none;
		}
	}

	&-description_button {
		@include g-link;

		margin-top: 5px;
	}

	&-link {
		@include g-link;

		color: $color-link-step;
		display: block;
		font-size: 15px;
		font-weight: 600;
		margin-top: 8px;
	}

	&-photos {
		border-radius: $global-radius;
		overflow: hidden;
	}
}
