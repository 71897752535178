@use 'sass:math';

.b-ideas_search {
	margin: 0 auto;
	max-width: 940px;
	text-align: center;

	&-title {
		@include g-heading_4;

		margin-bottom: 20px;

		@include media(lg-up) {
			font-size: 32px;
		}
	}

	&-subtitle {
		font-size: 15px;
		margin-bottom: 28px;

		@include media(lg-up) {
			margin-bottom: 40px;
		}
	}

	&-form {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
		text-align: left;

		@include media(lg-up) {
			margin-bottom: 40px;
		}
	}

	&-button {
		border-radius: 0 #{math.div($size-input-height, 2)} #{math.div($size-input-height, 2)} 0;
		margin-left: -#{$global-line};
		padding: 0 15px 0 10px;
	}

	&-caption {
		font-size: 15px;
		font-weight: 600;
		margin-bottom: 20px;

		@include media(lg-up) {
			display: inline-block;
			margin: 0 20px 0 0;
		}
	}

	&-item {
		align-items: center;
		display: inline-flex;
		font-size: 15px;
		line-height: 1;
		margin: 7px 5px;
	}

	.b-form_section {
		margin-bottom: 0;
	}
}
