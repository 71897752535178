.b-anchors_line {
	align-items: baseline;
	background-color: $color-warning-obj;
	border-bottom: 1px solid $color-divider;
	border-top: 1px solid $color-divider;
	color: $color-primary;
	display: flex;
	font-size: 15px;
	font-weight: 500;
	justify-content: space-around;
	padding: 20px 25px;

	@include media(sm) {
		flex-wrap: wrap;

		.l-content & {
			justify-content: flex-start;
			margin: 0 #{-(grid-margin(sm))};
		}
	}

	@include media(md-up) {
		font-size: 17px;
		padding: 20px 32px;
	}

	&-title {
		color: $color-title;
		font-size: 20px;
		font-weight: 700;

		@include media(md-down) {
			.l-content & {
				display: none;
			}
		}
	}

	&-icon {
		color: $color-accent;
		margin-left: 4px;
		opacity: 0.7;
	}

	&-link {
		@include media(sm) {
			padding: 5px 0;

			.l-content & {
				padding: 10px 15px;
				width: 50%;
			}
		}
	}

	&.m-buytogether {
		border: 0;
		font-size: 17px;
		justify-content: center;
		margin-top: 30px;
		padding: 10px 20px;

		@include media(sm) {
			margin-left: -(grid-margin(sm));
			margin-right: -(grid-margin(sm));
		}

		@include media(lg-up) {
			justify-content: flex-start;
			margin-top: 45px;
		}
	}

	&.m-buytogether &-title {
		margin-right: 15px;
	}

	&.m-no_bottom_padding {
		@include media(lg-up) {
			margin-bottom: -40px;
		}
	}
}
