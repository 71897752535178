.b-featured_category {
	@include media(xl) {
		margin: 0 auto;
		max-width: $content-limit-xl;
		padding: 0 10px;
	}

	&-grid {
		display: grid;
		grid-gap: 16px;
		grid-template-columns: repeat(2, 1fr);

		@include media(lg-up) {
			grid-gap: 20px;
			grid-template-columns: repeat(4, 1fr);
		}

		.b-carousel,
		.b-carousel-track {
			display: contents;
		}

		.b-carousel {
			.b-carousel-ctrl {
				display: none;
			}
		}

		.m-grid_mobile_offer_carousel & {
			@include media(md-down) {
				display: block;

				.b-carousel {
					display: block;

					&.m-prev_visible::before,
					&.m-next_visible::after {
						background: linear-gradient(270deg, transparent, white);
						content: '';
						display: block;
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 50px;
					}

					&.m-next_visible::after {
						background: linear-gradient(90deg, transparent, white);
						left: auto;
						right: 0;
					}
				}

				.b-carousel-ctrl {
					display: initial;
					margin-top: 0;

					&.m-next {
						left: auto;
						right: -10px;
					}

					&.m-prev {
						left: -10px;
					}
				}

				.b-carousel-track {
					display: flex;
					gap: 10px;
					padding: 0;

					.b-promo_impression {
						flex: 1 0 75%;
						max-width: 300px;
						padding: 5px;
					}
				}
			}
		}
	}

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 20px;
		text-align: center;

		@include media(xl) {
			font-size: 32px;
			margin-bottom: 30px;
		}

		&.m-promotional {
			font-size: 32px;
			font-weight: 700;
			line-height: 40px;
			margin-bottom: 5px;
			text-align: center;

			@include media(sm) {
				font-size: 24px;
				line-height: 30px;

				.l-grid_layout & {
					margin-bottom: 15px;
				}
			}
		}
	}

	&-subtitle {
		color: $color-black;
		font-size: 15px;
		line-height: 22.5px;
		margin-bottom: 35px;
		text-align: center;

		@include media(sm) {
			margin-bottom: 15px;
		}
	}

	&-tile {
		height: 100%;
		position: relative;
	}

	&-link {
		display: block;
		height: 100%;
	}

	&-footnote {
		padding: 20px 0;
		text-align: center;

		@include media(sm) {
			padding: 10px 0;
		}
	}

	.m-category-grid &-footnote {
		padding: 30px 0 25px;
		text-align: center;

		@include media(md) {
			padding-bottom: 20px;
		}

		@include media(sm) {
			padding: 10px 0 20px;
		}
	}

	&-social {
		color: $color-burgundy;
		display: flex;
		justify-content: center;
		text-align: center;
	}

	&-social_label {
		font-size: 15px;
		font-weight: 600;
		line-height: 150%;
		padding-right: 10px;
	}

	&-social_list {
		align-items: center;
		display: flex;
		gap: 15px;
	}
}
