.b-slot_bg {
	overflow: hidden;

	&.m-category-carousel {
		background-color: $color-black;
	}

	&.m-category-grid {
		background-color: transparent;

		@include media(md-up) {
			padding-bottom: 5px;
		}

		@include media(xl) {
			padding: 40px 24px 15px;
		}

		@include media(sm) {
			padding-bottom: 0;
		}

		&:first-child {
			padding-top: 5px;
		}
	}

	&.m-category-tiles {
		margin: 0 auto;
		max-width: $content-limit-xl;
		padding-left: 24px;
		padding-right: 24px;

		@include media(md) {
			padding-left: 32px;
			padding-right: 32px;
		}

		@include media(sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&.m-carousel-wishlist {
		background-repeat: no-repeat;
		background-size: cover;
		margin-bottom: 25px;
	}

	.l-pdp-slots & {
		@include media(md) {
			padding-left: 22px;
			padding-right: 22px;
		}

		@include media(sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&-video {
		margin-bottom: 50px;
		padding-bottom: 65px;
	}

	&-container {
		position: relative;
		z-index: 1; // compensation of -1 fro items

		&.m-category-grid {
			margin: 0 auto;
			max-width: $content-limit-xl;
		}
	}

	&-item {
		color: var(--item-color);
		left: var(--left, 0);
		pointer-events: none;
		position: absolute;
		top: var(--top, 0);
		width: var(--size);
		z-index: -1;

		@include media(md) {
			left: var(--left-md, var(--left, 0));
			top: var(--top-md, var(--top, 0));
			width: var(--size-md, var(--size));
		}

		@include media(sm) {
			left: var(--left-sm, var(--left-md, var(--left, 0)));
			top: var(--top-sm, var(--top-md, var(--top, 0)));
			width: var(--size-sm, var(--size-md, var(--size)));
		}

		&.m-number-1 {
			color: var(--item-color-1);
			left: var(--left-1, 0);
			top: var(--top-1, 0);
			width: var(--size-1);

			@include media(md) {
				left: var(--left-md-1, var(--left-1, 0));
				top: var(--top-md-1, var(--top-1, 0));
				width: var(--size-md-1, var(--size-1));
			}

			@include media(sm) {
				left: var(--left-sm-1, var(--left-md-1, var(--left-1, 0)));
				top: var(--top-sm-1, var(--top-md-1, var(--top-1, 0)));
				width: var(--size-sm-1, var(--size-md-1, var(--size-1)));
			}
		}

		&.m-number-2 {
			color: var(--item-color-2);
			left: var(--left-2, 0);
			top: var(--top-2, 0);
			width: var(--size-2);

			@include media(md) {
				left: var(--left-md-2, var(--left-2, 0));
				top: var(--top-md-2, var(--top-2, 0));
				width: var(--size-md-2, var(--size-2));
			}

			@include media(sm) {
				left: var(--left-sm-2, var(--left-md-2, var(--left-2, 0)));
				top: var(--top-sm-2, var(--top-md-2, var(--top-2, 0)));
				width: var(--size-sm-2, var(--size-md-2, var(--size-2)));
			}
		}

		.m-promotional & {
			left: 50%;
			max-width: 100%;
			transform: translateX(-50%);
			width: $content-limit-lg;

			img {
				left: var(--left, 0);
				position: absolute;
			}
		}
	}
}
