.b-rounded_tiles {
	margin-left: auto;
	margin-right: auto;
	padding: 20px 0 10px;

	@include media(sm) {
		padding: 20px 0;
	}

	@include media(md) {
		max-width: 400px; //HCA-1227
	}

	@include media(lg-up) {
		max-width: $content-limit-lg;
	}

	&-main_title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 30px;
		text-align: center;

		@include media(lg-up) {
			font-size: 32px;
		}
	}

	&-item {
		max-width: 16.6667%;
		min-width: 16.6667%;

		@include media(md) {
			max-width: 30%;
			min-width: 30%;
		}

		@include media(sm) {
			max-width: 30%;
			min-width: 30%;
		}
	}

	&-link {
		display: block;
		text-align: center;

		@include hover-supported {
			&:hover {
				text-decoration: none;
			}
		}
	}

	&-image {
		.g-image {
			border-radius: 50%;
			height: 100px;
			margin: 0 auto;
		}
	}

	&-actions {
		margin: 40px 0 0;
		text-align: center;
	}

	&-cta {
		display: block;
		height: 100%;
	}

	&-title {
		color: $color-title;
		font-size: 15px;
		font-weight: 600;
		margin-top: 20px;
		padding: 0 10px;
	}

	.b-artisan &-title {
		text-decoration: underline;
	}

	&-link:hover &-title {
		text-decoration: underline;
	}

	.b-artisan &-link:hover &-title {
		text-decoration: none;
	}
}
