.b-highlights {
	margin: 0 auto;
	max-width: $content-limit-lg;

	&-title {
		@include g-heading_4;

		color: $color-title;
		margin-bottom: 20px;
		text-align: center;

		@include media(lg-up) {
			font-size: 32px;
			margin-bottom: 30px;
		}
	}

	&-grid {
		@include g-grid;
	}

	&-tile {
		grid-column: span 6;
		position: relative;

		@include media(md-up) {
			&.m-col_2 {
				grid-column: span 6;
			}

			&.m-col_3 {
				grid-column: span 4;
			}
		}

		&.m-col_2 {
			.g-content_tile-caption {
				padding: 20px;
			}
		}

		&.m-col_4 {
			grid-column: span 3;
		}
	}

	&-tile_content {
		height: 100%;
	}

	&-link {
		display: block;
		height: 100%;

		&:hover {
			text-decoration: none;
		}
	}
}
