.b-content_image {
	text-align: center;

	&-full_width {
		.g-image {
			@include media(sm) {
				margin: 0 #{-(grid-margin(sm))};
				max-width: calc(100% + #{(grid-margin(sm)) * 2});
			}
		}
	}

	&-button {
		margin-top: 33px;
	}
}
