.b-currated {
	margin: 0 auto;
	max-width: $content-limit-xl;
	padding: 0 24px;

	@include media(md-down) {
		padding: 0;
	}

	&-component {
		display: flex;
		gap: 20px;

		@include media(sm) {
			display: block;
		}
	}

	&-main {
		display: flex;
		position: relative;
		width: 48.5%;

		@include media(sm) {
			width: 100%;
		}
	}

	&-secondary {
		width: 100%;
	}

	&-main + &-secondary {
		width: calc(51.5% - 20px);

		@include media(sm) {
			width: 100%;
		}
	}

	&-tile {
		background-color: $color-white;
		border-radius: 4px;
		box-shadow: 0 0 8px 0 hsla(0, 0%, 0%, 0.1);
		height: 100%;
		overflow: hidden;
	}

	&-image_wrap {
		position: relative;
	}

	&-copy {
		font-size: 13px;
		line-height: 19px;
		min-height: 78px;
		padding: 10px 15px;
	}

	&-copy_inner {
		@include line-clamping(2);
	}

	&-promo {
		background: rgba(255, 255, 255, 0.9);
		color: $color-burgundy;
		font-size: 20px;
		font-weight: 700;
		left: 0;
		line-height: 30px;
		margin: auto;
		max-width: 90%;
		padding: 5px 10px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 50%;
		transform: translate(0, -50%);
		width: fit-content;
	}

	&-link {
		bottom: 10px;
		position: absolute;
		right: 10px;
	}

	&-image_main {
		object-fit: cover;
	}

	.b-product_tile {
		padding-bottom: 0;
	}

	.b-product_tile-link {
		font-size: 13px;
		line-height: 19px;
		min-height: 38px;
	}

	.b-product_tile-info {
		min-height: 78px;
		padding: 10px;
	}

	.b-product_tile-cta.m-idea,
	.b-product_tile-bottom,
	.b-product_tile-price {
		display: none;
	}

	.b-product_tile-low_stock,
	.b-promotion {
		margin-top: 3px;
		position: absolute;
	}

	.b-promotion-message {
		margin-top: 0;
	}
}
